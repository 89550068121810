import React, { createContext, useEffect, useState } from 'react';
import store from '../store/store';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    console.log('this is user data', userData);

    useEffect(() => {
        fetch(`https://face-prep-server.vercel.app/getUser/${localStorage?.getItem('email')}`)
            .then(res => res.json())
            .then(data => {
                setUserData(data);
            })

    }, [])

    useEffect(() => {
        const x = store.getState().login.isAuthenticated;
        console.log('this is authenticated', x);
    }, [])

    const value = {
        loggedIn,
        setLoggedIn,
        userData,
    }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;